 .body, html{
    height: 100%;
}

.overlay {
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(159,134,65, 0.7);
  /* opacity: 0.7; */
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  
}


@font-face {
  font-family: 'AvenirBold';
  src: local('AvenirBold'), url(./AvenirBold.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/*
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    padding:25px;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }
  

  .hoverablecard:hover{
    transform: scale(1.05);
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    transition: all 0.3s ease;
} */